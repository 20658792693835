import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Section } from '~/elements/Section';
import { Grid } from '~/elements/Grid';
import { Column } from '~/elements/Column';
import { Container } from '~/elements/Container';
import { InlineLink } from '~/elements/InlineLink';
import { Box } from '~/elements/Box';
import { List } from '~/elements/List';
import { Icon } from '~/elements/Icon';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

const RoleGroupTrigger = styled.summary`
  cursor: pointer;
  background-image: none;
  -webkit-appearance: none;
  outline: none;

  &::marker,
  &::-webkit-details-marker {
    display: none;
    content: '';
  }

  h4 {
    display: flex;
    gap: var(--space-2);
    align-items: center;
    padding-right: 32px;
  }
`;

const RoleGroup = styled.details`
  scroll-margin-top: 80px;

  &:last-child {
    border-bottom: 1px solid var(--color-gray-200);
  }
  .icon--chevron-down {
    transition: 0.1s transform;
  }

  &[open] {
    .icon--chevron-down {
      transform: rotate(180deg);
    }
    ${RoleGroupTrigger} {
      ${Box} {
        display: none;
      }
    }
  }
`;

const RoleTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--space-4) 0;
  border-top: 1px solid var(--color-gray-200);
`;

export const SectionRoles: React.FC = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash) {
      document.getElementById(window.location.hash.replace('#', ''))?.setAttribute('open', 'true');
    }
  }, []);

  return (
    <Section pt={{ _: 0, md: 0 }}>
      <Container>
        <Grid zIndex="10" position="relative">
          <Column gridColumn={{ _: '1 / span 12', md: '2 / span 7', lg: '2 / span 6' }}>
            <Grid gridTemplateColumns={1} gapY={8}>
              <Heading as="h2" weight="semibold" size="600">
                Open roles
              </Heading>
              <List>
                <RoleGroup id="business-operations">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#business-operations')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        Business Operations
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    As a founding member of Column’s Business Operations team, you will be responsible for helping lay
                    the foundation for the company’s next decade of growth. This team will own Column’s most important
                    strategic and operational initiatives, with a heavy focus on designing new operating systems that
                    will directly impact our ability to scale. Individuals in this role will serve as the “GM” of a
                    particular product or project, diving deep into a wide range of challenges across product strategy,
                    pricing optimization, compliance operations, and internal tool/platform development. You will have
                    the opportunity to experience having P&L ownership of a particular product, while working alongside
                    senior leadership at Column to take our business to the next level.
                  </Text>
                  <Text pb={8} pr={8}>
                    This position will report to our Head of Business Operations, and will be in-person in our
                    Presidio-based office in San Francisco (3-4 days/week) - we will support relocation for the right
                    candidate.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/2a4b0b23-44a0-4634-9f36-bfbd8cab34f9">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="growth">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#growth')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        Growth
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    As one of the first members of the Growth team, you’ll be responsible for building and overseeing
                    all aspects of our customer management while acting as a trusted partner, advisor and advocate for a
                    wide variety of customers. You will also be in charge of designing and implementing the strategy,
                    processes, and content needed to help us scale this key function. Column's Growth team is not your
                    typical Customer Success or Account Management team in that Column is a critical, often existential,
                    partner for our customers, our customer relationships are deeply collaborative and complex, and each
                    customer contributes meaningfully to our revenue. This role is highly cross-functional and
                    collaborative, partnering closely with Column’s Executive, Solutions Engineering, Sales, Compliance,
                    and Product teams to ensure a top-notch customer experience. You’ll also represent the voice of the
                    customer to help inform Column's strategy, including the product roadmap, go-to-market motions and
                    more. Success in this role also means becoming an expert on the Financial Technology space and on
                    how companies partner with Column (our customers include many key industry players such as Brex and
                    Carta). This role reports to the Head of Go-To-Market and is an in-person role, where you’ll be
                    expected to work out of our San Francisco Presidio-based office 3+ days a week.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/899c09dc-1e92-4063-bb90-fc3ebe26965e">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="new-business">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#new-business')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        New Business
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    We’re hiring for a founding member of Column’s sales team. In this role, you’ll develop new
                    strategies to attract, engage, and qualify prospects. You’ll be the face of Column in the broader
                    fintech community and play a key part in driving the success of our business. This role will require
                    the ability to think on your feet, speak confidently about Column’s products and differentiation to
                    prospects, and understand an API-first product in a complex industry.
                  </Text>
                  <Text pb={8} pr={8}>
                    This role reports directly to our Head of Go-To-Market and will be expected to work alongside our
                    team in our San Francisco Presidio-based office 3+ days a week.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/6a4815af-f5a2-4016-a819-94971b54bc28">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="credit-partnerships-and-lending">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#credit-partnerships-and-lending')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        Credit Partnerships & Lending
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    We’re hiring an experienced credit professional to join our next major area of investment. In this
                    role you will build a high-quality credit portfolio, and have a large scope that spans investments,
                    partner programs, and loan acquisitions. We’re looking for someone who’s motivated to improve our
                    processes, impact our lending strategy, and make critical business-moving decisions.
                  </Text>
                  <Text pb={8} pr={8}>
                    This role will work alongside our high-caliber team, that have already achieved success in the
                    world’s largest banks and fintechs, in our beautiful Presidio-based San Francisco office.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/9378f117-1b4c-4a07-90da-971e0dec0939">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="finance-operations">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#finance-operations')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        Finance Operations
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    We’re hiring an experienced finance professional to support and operationalize our financial
                    processes across accounting, finance, treasury, and credit operations for our bank. You’ll need a
                    strong foundation in accounting, and be motivated to scale our business and financial operations.
                  </Text>
                  <Text pb={8} pr={8}>
                    This role will work alongside our financial leaders in our Presidio-based office in San Francisco to
                    build a top-tier financial function.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/20e6b469-541f-4ea2-85c9-6d1c7f9e68b3">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="bsaaml-investigator">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#bsaaml-investigator')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        BSA/AML Investigator
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    Column's Financial Crimes team is responsible for protecting the Bank and its customers from money
                    laundering, fraud, economic sanction evaluations, foreign corruption, and terrorist financing
                    violations. Our program is designed to ensure the Bank, U. S. financial system, and our country are
                    protected from illicit actors who exploit banking services with nefarious and criminal intent.
                  </Text>
                  <Text pb={8} pr={8}>
                    The BSA/AML Investigator performs triage investigations of transaction monitoring system generated
                    alerts and referrals, including identifying root causes of the alert, sources and uses of funds, and
                    potentially suspicious activity. The BSA/AML Investigator is responsible for conducting
                    investigations to identify potentially suspicious financial and/or fraudulent activity, to include
                    money laundering, terrorist financing, fraud and/or any other financial risks or crimes. The BSA/AML
                    Investigator may also contribute to customer due diligence reviews and enhanced due diligence.
                    Finally, the Investigator may support OFAC screening reviews and the triage of potential sanctions
                    hits.
                  </Text>
                  <Text pb={8} pr={8}>
                    This role reports directly to the Bank's BSA/AML Officer, and can be a remote position with
                    occasional travel to the Bank's office in San Francisco.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/f03af329-9035-4aff-9a7d-e5aabfb8218c">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="aml-quality-assurance">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#aml-quality-assurance')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        AML Quality Assurance
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    We’re hiring an experienced Quality Assurance Specialist to play a critical role in our anti-money
                    laundering (AML) and BSA compliance efforts for our Bank. We take regulatory compliance very
                    seriously here and view our tech-forward approach and commitment to exceeding regulatory
                    expectations as one of the most important competitive advantages we have as a business. You’ll lean
                    on your expertise in BSA regulations, AML laws, and KYC/CDD requirements to help us build, maintain,
                    and improve a best-in-class BSA program.
                  </Text>
                  <Text pb={8} pr={8}>
                    This position will report to the Bank’s Head of Quality Assurance. This role can be in-person in our
                    Presidio-based San Francisco office, or remote with frequent travel.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/78516837-7da9-4717-a378-19441d8fea4a">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="kyc-operations">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#kyc-operations')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        KYC Operations
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    The KYC Operations role is responsible for performing quality reviews on customer onboardings, KYC
                    files, periodic reviews, and remediations to ensure applicable laws, regulations, and internal
                    policy requirements are fully satisfied and accurately documented. We are looking for someone with
                    3+ years of experience within financial crimes, specifically KYC, at a financial institution;
                    attention to detail is a must, experience executing against CIP/CDD/EDD procedures is required, and
                    experience in a quality assurance or quality control role would be a bonus.
                  </Text>
                  <Text pb={8} pr={8}>
                    While an in-person presence in the Bank’s Presidio-based office is preferred, this role is open to
                    remote applicants with the expectation of periodic travel.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/09b76fc3-bd25-44b8-b2ce-39ea1b14b2e7">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="aml-operations">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#aml-operations')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        AML Operations
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    The AML Operations role plays a crucial role in safeguarding Column from financial crimes and
                    ensuring compliance with AML and Sanctions regulations. This position will manage an AML Ops
                    program, including operationalizing quality assurance feedback, supporting procedure design and
                    implementation, and owning internal governance reporting and metrics. This role will also assist in
                    developing and executing BSA/AML and OFAC training programs, including the development of
                    appropriate role-based BSA/AML and OFAC training and delivery of continuous feedback to the
                    Operations team.
                  </Text>
                  <Text pb={8} pr={8}>
                    This role reports directly to the Bank’s AML Operations Lead. While an in-person presence in the Bank’s
                    Presidio-based office is strongly preferred, this role is open to remote applicants with the
                    expectation of periodic travel.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/1a187ce8-59b3-4424-9c50-40208de37d60">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="due-diligence-advisory">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#due-diligence-advisory')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        Due Diligence Advisory
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    The Due Diligence Advisory role is responsible for the execution of Column's KYC program, inclusive
                    of CIP, CDD, and EDD. This individual will own onboarding and periodic reviews for the Bank's
                    high-risk customers, as well as enhancing Column's KYC program, procedures, and controls so that
                    they remain best-in-class. This role will also work closely with Compliance stakeholders in
                    performing the AML components of due diligence for the Bank's fintech partnerships.
                  </Text>
                  <Text pb={8} pr={8}>
                    This role reports directly to the Bank’s Chief Compliance Officer. While an in-person presence in
                    the Bank’s Presidio-based office is strongly preferred, this role is open to remote applicants with
                    the expectation of periodic travel.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/481651ef-1bc8-4019-a06b-77429c535ec0">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="third-party-compliance">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#third-party-compliance')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        Third-Party Compliance
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    We’re hiring for our Third-Party Compliance program, this team is responsible for strong ongoing
                    management of the Bank’s partnership business and plays a critical part in the strategic oversight
                    of our partnership portfolio. Column is committed to executing a best-in-class program – as a
                    result, this role requires creativity, a deep understanding of regulatory expectations, and a unique
                    ability to manage risk. The regulatory landscape for fintech-banking partnerships is evolving daily,
                    and there is no one-size-fits-all roadmap for partner oversight. If you enjoy working in a
                    fast-paced environment and thinking through scalable solutions for partner oversight and risk
                    management, then this role is for you.
                  </Text>
                  <Text pb={8} pr={8}>
                    This position will report to the Bank’s Head of Regulatory Compliance. Preference for this role to
                    be based in-person in our Presidio-based office in San Francisco 3-4 days per week; will also
                    consider remote with frequent travel.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/424b03a5-7732-440f-bb9b-0cf3e6ffdc58">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="payment-operations">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#payment-operations')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        Payment Operations (New Grad)
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    We’re hiring for a member of our Payment Ops team. In this role you’ll own processes,
                    customer service for related cases, and scalability for a best in class payments function at a
                    technology forward bank. This is an excellent opportunity for someone who’s looking to dive into
                    strategy, developing processes, and true operations ownership. Bonus points if you’re interested in,
                    or have experience with payments at a financial institution or fintech.
                  </Text>
                  <Text pb={8} pr={8}>
                    This role reports directly to the Head of Solutions Engineering and will work alongside our team
                    in-person in our Presidio-based office in San Francisco 3+ days a week.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/c349d594-33a2-49a2-a168-afa8d8226572">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="recruiter">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#recruiter')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        Recruiter
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    As an early member of Column's Recruiting team, you will be responsible for identifying and
                    attracting top-tier talent that is critical to driving the company's growth. In this role, you will
                    be responsible for the end-to-end recruitment lifecycle (from sourcing to closing), with the
                    opportunity to recruit across all of Column's teams (technical and non-technical). Throughout the
                    hiring process, we'll look to you to help craft an excellent candidate experience, while serving as
                    a key thought partner to leadership and internal stakeholders for building exceptional teams at a
                    company that values hiring top talent.
                  </Text>
                  <Text pb={8} pr={8}>
                    This role reports directly to the Head of People, and is an in-person position where you'll be
                    expected to work out of our Presidio-based office in San Francisco 3+ days a week.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/6ef3b475-9d41-4391-8260-c85790c01ae6">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="software-engineer-backend">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#software-engineer-backend')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        Software Engineer (Backend)
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    We’re a lean and experienced team of nine engineers, and we’re seeking a talented Backend engineer
                    to join us. We write mostly Go, but experience there isn’t that important. We generally prefer
                    candidates with at least five years of production coding experience with a strongly typed back-end
                    language on a high-caliber team.
                  </Text>
                  <Text pb={8} pr={8}>
                    This role reports directly to our Founder/CEO and will work alongside our team in-person in our
                    Presidio-based office in San Francisco 3+ days a week.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/d9162229-c756-411e-a1cb-796820e77f33">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="software-engineer-infrastructure">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#software-engineer-infrastructure')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        Software Engineer (Infrastructure)
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    We're a lean and experienced team. We like our current size of nine engineers. For an excellent
                    candidate we're open to hiring one or two more. We write mostly Go, but experience there isn't that
                    important....it's a pretty easy language to learn. We generally prefer candidates with at least five
                    years of production coding experience at a high caliber and high velocity team. We'll require a
                    take-home that does take time, so if that's a non-starter for you we're sorry. We find it the best
                    way for you to show your skills, without being overly burdensome on the team!
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/8ad3cf7b-7ea3-47f6-86c4-c83a9d78ff50/application">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="software-engineer-product">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#software-engineer-product')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        Software Engineer (Product)
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8}>
                    Column is looking for a full-stack product engineer to build high-quality, intuitive banking user
                    experiences. We're a lean and experienced team of nine, and want to grow thoughtfully with
                    exceptional people. In this role you will be writing Go, React, and SQL, and working on our customer
                    dashboard. We generally prefer candidates with at least five years of production coding experience
                    at a high-caliber and high-velocity team.
                  </Text>
                  <Text pb={8} pr={8}>
                    This role reports directly to our Founder/CEO and will work alongside our team in-person in our
                    Presidio-based office in San Francisco 3+ days a week.
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/003784f9-9131-4fa6-82d0-96c27032d2fc">
                      Please apply here
                    </InlineLink>
                  </Text>
                </RoleGroup>

                <RoleGroup id="surprise-us">
                  <RoleGroupTrigger onClick={() => history?.pushState(null, '', '#surprise-us')}>
                    <RoleTitle>
                      <Heading as="h4" weight="semibold">
                        I don't fit into any of these roles!
                      </Heading>
                      <Icon.ChevronDown />
                    </RoleTitle>
                  </RoleGroupTrigger>
                  <Text pb={8} pr={8} id="other">
                    You get Column and you think you can drive value on day one. We'll be honest the bar will be pretty
                    high for us to hire outside of these roles - but surprise us. Give us a compelling narrative and
                    hustle....and we promise we'll read the email!
                  </Text>
                  <Text pb={8} pr={8}>
                    <InlineLink href="https://jobs.ashbyhq.com/column/form/interest-form">Please apply here</InlineLink>
                  </Text>
                </RoleGroup>
              </List>
              <Text mt={8}>
                We're mostly hiring locally in the San Francisco Bay Area, however will be open to remote for
                exceptional candidates. Sadly we won't have the time to respond to everyone, but we do read every
                application we get. Please go through the application links, direct emails are strongly discouraged :)
              </Text>
              <Text mt={8}>
                <InlineLink href="/legal/notice-to-recruiters-and-staffing-agencies">
                  Notice to Recruiters & Staffing Agencies
                </InlineLink>
              </Text>
            </Grid>
          </Column>
        </Grid>
      </Container>
    </Section>
  );
};

export default SectionRoles;
